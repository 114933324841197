<template lang="pug">
head-meta(title-page="Реквизиты Chiedohome", description="Вы можете воспользоваться нашими реквизитами для оформления платежных документов.")
the-requisites
</template>

<script>
import HeadMeta from '../components/_layout/HeadMeta'
import TheRequisites from '../components/requisites/TheRequisites'

export default {
  components: { TheRequisites, HeadMeta },
  name: 'RequisitesPage'
}
</script>
